const BREAK_POINT = 768;
const PAGE_WIDTH = 1000;
const PAGE_WIDTH_XL = 1200;

/**
* タブレットではPCのViewportで表示する
*
*/
var breakPoint = BREAK_POINT;
var pcViewWidth = PAGE_WIDTH_XL;
var vp = document.querySelector('[name="viewport"]');
if (vp) {
	var media = matchMedia('(min-width: ' + (breakPoint - 1) + 'px)');
	if (media.matches) {
		vp.content = 'width=' + pcViewWidth;
	}
}

/**
* pagetop
*
*/
function pageTop (target) {
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			target.attr('data-hide', 'false');
		} else {
			target.attr('data-hide', 'true');
		}
	});
}

/**
 * DOM読み込み後実行
 */
$(function () {
	/**
	* ハンバーガーメニュー
	*
	*/
	$('.c-header__spbtn').on('click', function () {
		if ($(this).attr('data-open')) {
			$(this).attr('data-open', '');
			$('.c-header__nav').fadeOut();
			$('body').attr('data-scroll-prevent', '');
		} else {
			$(this).attr('data-open', 'true');
			$('.c-header__nav').fadeIn();
			$('body').attr('data-scroll-prevent', 'true');
		}
	});

	$('.c-header__nav a[href*="#"]').on('click', function () {
		if ($(window).width() < breakPoint) {
			$('.c-header__spbtn').attr('data-open', '');
			$('.c-header__nav').fadeOut();
			$('body').attr('data-scroll-prevent', '');
		}
	});

	/**
	* ブレークポイント切り替わりのタイミングで実行される処理
	*
	*/
	matchMedia(`(min-width: ${BREAK_POINT - 1}px)`).addListener((media) => {
		if (media.matches) {
			/**
			* ブレークポイントの外側
			*/
			$('.c-header__spbtn').attr('data-open', 'true');
			$('.c-header__nav').css('display','block');
			$('body').attr('data-scroll-prevent', '');
		} else {
			/**
			* ブレークポイントの内側
			*/
			$('.c-header__spbtn').attr('data-open', '');
			$('.c-header__nav').css('display','none');
			$('body').attr('data-scroll-prevent', '');
		}
	});

	/**
	* pagetop
	*
	*/
	var $pagetop = $('.c-footer__pagetop a');
	pageTop ($pagetop);

	/**
	* objectfit
	*
	*/
	objectFitImages();

	/**
	* スムーズスクロール
	*
	*/
	$('a, area').bcScrollTo();
});
